<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="companyShopList-container">
    <div class="title-container">
      <div>店铺中心</div>
      <div>
        <div class="common-upload-container" style="margin: 0;display: inline-block">
          <el-button class="common-screen-btn" type="primary" :loading="btnLoading">批量上传分配客服</el-button>
          <input v-if="!btnLoading" class="common-upload-input" @input="(e) => {serviceUpload(e)}" type="file"/>
        </div>
        <el-button class="common-screen-btn" type="text" @click="() =>downLoad()">子账号分配客服模板</el-button>
      </div>
    </div>
    <div class="common-Summary-container">
      <div class="Summary-item">
        <div class="item-title">累计店铺总数</div>
        <div class="item-black">{{ totalNum || "0" }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">正在服务店铺数</div>
        <div @click="
            () => {
              collect(
                'serviceShopNum',
                '正在服务店铺数',
                [{ name: '店铺名称', type: 'input', modelKey: 'shopName' },
                { name: '所属客户', type: 'input', modelKey: 'customerName' },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '店铺服务状态', colProp: ['state'] },
                  { name: '关联订单数', colProp: ['orderCount'] }
                ]
              );
            }
          "
           class="item-num">{{ serviceShopNum || '0' }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">待服务店铺数</div>
        <div @click="
            () => {
              collect(
                'unServiceShopNum',
                '待服务店铺数',
                [{ name: '店铺名称', type: 'input', modelKey: 'shopName' },
                { name: '所属客户', type: 'input', modelKey: 'customerName' },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '店铺服务状态', colProp: ['state'] },
                  { name: '关联订单数', colProp: ['orderCount'] }
                ]
              );
            }
          "
           class="item-num">{{ unServiceShopNum || '0' }}</div>
      </div>
      <div class="Summary-item Summary-item-line">
        <div class="item-title">终止服务店铺数</div>
        <div @click="
            () => {
              collect(
                'endServiceShopNum',
                '终止服务店铺数',
                [{ name: '店铺名称', type: 'input', modelKey: 'shopName' },
                { name: '所属客户', type: 'input', modelKey: 'customerName' },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '店铺服务状态', colProp: ['state'] },
                  { name: '关联订单数', colProp: ['orderCount'] }
                ]
              );
            }
          "
           class="item-num">{{ endServiceShopNum || '0' }}</div>
      </div>
      <div class="Summary-item" style="align-items: center;">
        <el-select style="width: 80px;margin-left: 32px" v-model="topDate">
          <el-option label="今日" :value="1"></el-option>
          <el-option label="昨日" :value="2"></el-option>
          <el-option label="本周" :value="3"></el-option>
          <el-option label="本月" :value="5"></el-option>
        </el-select>
      </div>
      <div v-show="topDate==2" class="Summary-item">
        <div class="item-title">新增店铺数</div>
        <div @click="
            () => {
              collect(
                'yesdayNum',
                '昨日新增店铺数',
                [{ name: '店铺名称', type: 'input', modelKey: 'shopName' },
                { name: '所属客户', type: 'input', modelKey: 'customerName' },
                { name: '店铺服务状态', type: 'select', modelKey: 'state',selOpt: [{id: 1,name: '正在服务'},{id: 0,name: '服务终止'},{id: 2,name: '待服务'}]}
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '店铺服务状态', colProp: ['state'] },
                  { name: '关联订单数', colProp: ['orderCount'] }
                ]
              );
            }
          "
           class="item-num">{{ yesterdayNew || '0' }}</div>
      </div>
      <div v-show="topDate==1" class="Summary-item">
        <div class="item-title">新增店铺数</div>
        <div @click="
            () => {
              collect(
                'todayNum',
                '今日新增店铺数',
                [{ name: '店铺名称', type: 'input', modelKey: 'shopName' },
                { name: '所属客户', type: 'input', modelKey: 'customerName' },
                { name: '店铺服务状态', type: 'select', modelKey: 'state',selOpt: [{id: 1,name: '正在服务'},{id: 0,name: '服务终止'},{id: 2,name: '待服务'}]}
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '店铺服务状态', colProp: ['state'] },
                  { name: '关联订单数', colProp: ['orderCount'] }
                ]
              );
            }
          "
          class="item-num">{{ todayNum || "0" }}</div>
      </div>
      <div v-show="topDate==3" class="Summary-item">
        <div class="item-title">新增店铺数</div>
        <div @click="
            () => {
              collect(
                'lastweekNum',
                '本周新增店铺数',
                [{ name: '店铺名称', type: 'input', modelKey: 'shopName' },
                { name: '所属客户', type: 'input', modelKey: 'customerName' },
                { name: '店铺服务状态', type: 'select', modelKey: 'state',selOpt: [{id: 1,name: '正在服务'},{id: 0,name: '服务终止'},{id: 2,name: '待服务'}]}
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '店铺服务状态', colProp: ['state'] },
                  { name: '关联订单数', colProp: ['orderCount'] }
                ]
              );
            }" class="item-num">{{ weekNew || '0' }}</div>
      </div>
      <div v-show="topDate==5" class="Summary-item">
        <div class="item-title">新增店铺数</div>
        <div @click="
            () => {
              collect(
                'monthNum',
                '本月新增店铺数',
                [{ name: '店铺名称', type: 'input', modelKey: 'shopName' },
                { name: '所属客户', type: 'input', modelKey: 'customerName' },
                { name: '店铺服务状态', type: 'select', modelKey: 'state',selOpt: [{id: 1,name: '正在服务'},{id: 0,name: '服务终止'},{id: 2,name: '待服务'}]}
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '店铺服务状态', colProp: ['state'] },
                  { name: '关联订单数', colProp: ['orderCount'] }
                ]
              );
            }
          " class="item-num">{{ monthNum || "0" }}</div>
      </div>
    </div>
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>店铺名称:</span>
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.name"
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>所属平台:</span>
        <el-select class="common-screen-input" v-model="params.platId" placeholder="所属平台" @change="commonFun" clearable filterable >
          <el-option v-for="item in platList" :key="item.id" :label="item.platformName" :value="item.id" > </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>所属类目:</span>
        <el-select class="common-screen-input" v-model="params.categoryId" placeholder="所属类目" @change="commonFun" clearable filterable remote :remote-method="(val)=>{remoteCategory(val)}" @focus="selectClear">
          <el-option v-for="item in categoryList" :key="item.id" :label="item.platformName" :value="item.id" > </el-option>
          <div class="common-select-page">
            <div v-if="categoryParams.pageNum != 1" @click="() => {selectPage('up')}">上一页</div>
            <div v-if="categoryParams.pageNum == 1" style="color: #999">上一页</div>
            <div v-if="categoryList && categoryList.length == 10" @click="() => {selectPage('down')}">下一页</div>
            <div v-if="categoryList && categoryList.length < 10" style="color: #999">下一页</div>
          </div>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>店铺服务状态:</span>
         <el-select
          class="common-screen-input"
          v-model="params.state" placeholder="请选择" @change="commonFun" clearable filterable>
          <el-option key="2" label="待服务" value="2"></el-option>
          <el-option key="1" label="正在服务" value="1"></el-option>
          <el-option key="0" label="服务终止" value="0"></el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>所属客户:</span>
        <el-select
          class="common-screen-input"
          v-model="params.customerId"
          placeholder="请搜索客户"
          @change="commonFun"
          clearable
          filterable
          remote
          :remote-method="(val)=>{remoteMethod(val, 'custList')}"
        >
          <el-option
            v-for="item in finishedList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <el-button class="common-screen-btn" type="primary" @click="() => commonFun()"
        >查 询</el-button
      >
      <el-button
        class="common-screen-btn"
        plain
        @click="
          () => {
            resetBtn();
          }
        "
        >重 置</el-button
      >
    </div>
    <!-- <el-button class="common-screen-btn" type="primary" @click="addDrawer()"
      >新增店铺</el-button
    > -->
		<div class="gap"></div>
    <div class="common-padding">
      <el-table class="common-table" :data="tableData" :fit="true">
      <el-table-column width="80px">
        <template slot-scope="scope">
          <span>
            <img
              class="common-iconImg"
              v-if="scope.row.shop_logo"
              :src="scope.row.shop_logo"
            />
            <span v-if="!scope.row.shop_logo"
              ><i class="el-icon-user common-iconImg-MR"></i
            ></span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="shop_name"
        label="店铺名称"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="() => shopDetailShow(scope.row)">{{scope.row.shop_name || '--'}}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="customer_name"
        label="所属客户"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="platform_name"
        label="所属平台"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="category_name"
        label="所属类目"
        :formatter="tableColumn"
      ></el-table-column>
      <!-- <el-table-column
        prop="price"
        label="店铺金额"
        :formatter="tableColumn"
      ></el-table-column> -->
       <el-table-column label="店铺服务状态">
        <template slot-scope="scope">
          <div v-if="scope.row.state == 0" class="common-tableStatus-grey-bgc">终止服务</div>
          <div v-if="scope.row.state == 1" class="common-tableStatus-green-bgc">正在服务</div>
          <div v-if="scope.row.state == 2" class="common-tableStatus-blue-bgc">待服务</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="businessPeopleName"
        label="运营负责人"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="create_name"
        label="创建人"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        :formatter="tableColumn"
        label="创建时间"
      ></el-table-column>
      <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="
              () => {
                $router.push({
                  path: '/customer/company-shop-list/subaccount-list',
                  query: {
                    id: scope.row.id,
                    shopName: scope.row.shop_name,
                    companyName: scope.row.company_name,
                    companyId: params.companyId,
                    departName: scope.row.depart_name,
                    categoryName: scope.row.category_name,
                    platformName: scope.row.platform_name,
                    customerName: scope.row.customer_name,
                    startTime: scope.row.start_time,
                    endTime: scope.row.end_time,
                    shopLogo: scope.row.shop_logo,
                    fileUrl: scope.row.file_url,
                    isAssign: scope.row.isAssign || 0
                  },
                });
              }
            "
            >子账号管理</el-button
          >
          <!-- &nbsp;&nbsp;&nbsp;
          <el-popconfirm
            placement="top"
            title="确定删除吗？"
            @confirm="
              () => {
                deleteCmsShop(scope.row.id);
              }
            "
          >
            <el-button type="text" size="small" slot="reference"
              >删除</el-button
            >
          </el-popconfirm> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    </div>
    <shop-detail ref="shopDetail" :drawer="drawer" :shopOrder="shopOrder" @handleClose="handleClose" :userList="userList"></shop-detail>
    <!-- 头部报表弹 -->
    <header-dialog
      ref="homeChild"
      :methodFuc="customerHead"
      :comId.sync="this.params.companyId"
      :headerType="headerType"
      :dialogTit="dialogTit"
      :dialogVisible="dialogVisible2"
      :paramsTit="paramsTit"
      :childColName="childColName"
      @handleClose="handleClose2"
    ></header-dialog>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import ShopDetail from "./children/shopDetail.vue"
import { getCustList, selectPlatform, selectCategory } from "../../service/common.js";
import { getUserList, shopOrderList } from "../../service/manage.js";
import {
  getShopList,
  getPlatList,
  deleteCmsShop,
  customerHead,
  serviceUpload
} from "../../service/customer.js";
import { Config, tableColumn } from "../../utils/index.js";
// import { Cascader } from 'ant-design-vue';
import headerDialog from "../../components/common/commonHeaderDialog.vue";
export default {
  components: {
    Breadcrumb,
    ShopDetail,
    // aCascader: Cascader,
    headerDialog
  },
  data() {
    return {
      customerHead,
      increasing: 1,
      tableColumn,
      loading: false,
      btnLoading: false,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "营销", isLink: false },
        { title: "运营", isLink: false },
        { title: "店铺中心", isLink: false },
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      },
      userList: [], // 子组件用的下拉
      finishedList: [], // 所属客户下拉
      platList: [], // 所属平台
      categoryList: [], //类目下拉
      categoryParams: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      },
      platId: [], // 绑定的值
      tableRow: "", // 客户id用于编辑/查看
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company.filter((item) => { return item.id != 0 }), // 所属公司下拉
      companyId: this.$sto.get(Config.constants.userInfo).company_id,
      selectDepart: [],
      centerDialogVisible: false, // 修改密码员工弹窗
      drawer: false, // 新增员工弹窗
      roleSelect: "",
      tableData: [],
			totalNum: 0,
      todayNum: 0,
      yesterdayNew: 0,
      weekNew: 0,
			monthNum: 0,
      //////////////////////////
      headerType: "",
      dialogTit: "",
      dialogVisible2: false,
      paramsTit: [],
      childColName: [],
      topDate: 1,
      serviceShopNum: 0,
      unServiceShopNum: 0,
      endServiceShopNum: 0,
      shopOrder: [],//店铺关联订单列表
    };
  },
  mounted() {
    if (this.rolesCompany) {
      this.params.companyId = this.rolesCompany[0].id;
    }
    if(this.$route.query.shopName && this.$route.query.shopId) {
      this.params.name = this.$route.query.shopName
      this.shopDetailShow({id: this.$route.query.shopId}) 
    }
    // this.getCustList();
    this.getShopList();
    this.selectPlatform();
    this.selectCategory();
    // this.getPlatList();

    this.getUserList();
  },
  methods: {
    async serviceUpload(e) {
      this.btnLoading = true;
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (format != "xls" && format != "xlsx") {
        this.$message.error("仅支持xls、xlsx格式文件");
        e.target.value = "";
        this.btnLoading = false;
        return;
      }
      let { data, code } = await serviceUpload({ file: file });
      this.btnLoading = false;
      e.target.value = "";
      if (code == 200) {
        if (data) {
          this.$message.error(data);
        } else {
          this.$message.success("上传成功");
        }
      } else {
        this.$message.error(data);
      }
    },
    downLoad() {
      window.location.href = Config.api.url + `/excel/serviceSubExport`
    },
    selectClear () { // 下拉清空时触发
      this.categoryParams.pageNum = 1
      this.categoryParams.name = ''
      this.selectCategory()
    },
    selectPage(type) { // 下拉分页
      if (type == 'up') {
        this.categoryParams.pageNum--
      } else {
        this.categoryParams.pageNum++
      }
      this.selectCategory()
    },
    remoteCategory(val) { // 所属类目下拉-筛选
      this.categoryParams.pageNum = 1
      this.categoryParams.name = val
      this.selectCategory()
    },
    async selectCategory() { // 类目下拉
      let categoryParams = {...this.categoryParams}
      let { data } = await selectCategory(categoryParams)
      this.categoryList = data.list
    },
    async selectPlatform() { // 平台下拉
      let { data } = await selectPlatform({pageNum: -1})
      this.platList = data
    },
     remoteMethod (val, name) {
      if (name == 'custList') {
        this.getCustList(val)
      }
    },
    commonFun() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.getShopList();
    },
    async deleteCmsShop(id) {
      await deleteCmsShop({}, id);
      this.$message.success("删除成功");
      this.getShopList();
    },
    async getUserList() {
      // 商务负责下拉，暂用员工列表
      let resData = (await getUserList({ pageNum: -1, companyId: this.companyId })).data;
      this.userList = resData;
    },
    async getCustList(name) {
      // 所属客户下拉
      let resData = (await getCustList({customerName: name, companyId: this.companyId})).data;
      this.finishedList = resData;
    },
    async getPlatList() {
      // 所属平台/类目
      let resData = (await getPlatList({ pageNum: -1 })).data;
      this.platList = resData;
    },
    resetBtn() {
      this.params = {
        pageNum: 1,
        pageSize: 10,
        name: '',
        companyId: this.rolesCompany[0].id
      };
      this.platId = [];
      this.topDate = 1
      this.getShopList();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.getShopList();
    },
    async getShopList() {
      this.loading = true;
      // let platId = [...this.platId];
      let params = { ...this.params };
      if (params.departId && typeof params.departId == "object") {
        delete params.departId;
      }
      // if (platId && platId.length) {
      //   params.platId = platId[0];
      //   if (platId[1]) {
      //     params.categoryId = platId[1];
      //   }
      // }
      let resData = (await getShopList(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
			this.todayNum = resData.todayNum;
			this.totalNum = resData.totalNum;
      this.monthNum = resData.monthNum;
      this.yesterdayNew = resData.yesterday;
      this.weekNew = resData.lastweekNum;
      this.tableData = resData.records;
      this.serviceShopNum = resData.serviceShopNum
      this.unServiceShopNum = resData.unServiceShopNum
      this.endServiceShopNum = resData.endServiceShopNum
      this.loading = false;
    },
    companyFun() {
      //选择公司筛选
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.params.departId = [];
      this.getShopList();
    },
    handleClose() {
      this.drawer = false;
      this.getShopList();
    },
    async shopDetailShow(row) { // 店铺详情
      if (row) {
        if (row.isAssign == 1) {
          window.open(`/assign-order/receive-list?tabName=second&shopName=${row.shop_name}&shopId=${row.id}&disOrderNo=${row.disOrderNo}&from=order`)
        } else {
          this.getShopOrder(row.id).then(() => {
            this.$refs["shopDetail"].getDataInfo(row);
            if(this.$route.query.startDate && this.$route.query.endDate && this.$route.query.active){
              this.$refs["shopDetail"].systemServiceData();
              this.$refs["shopDetail"].getSetList();
              this.$refs["shopDetail"].getDefaultData();
            }
          })
          this.getUserList()
          this.drawer = true;
        }
      } else {
        this.$message.error("系统异常")
      }
    },
    /////////////////////////////////////////////////
    collect(type, name, screen, arr) {
      this.dialogTit = name;
      this.headerType = type;
      this.paramsTit = screen;
      this.childColName = arr;
      this.dialogVisible2 = true;
      this.$refs.homeChild.getHeaderList(type,Number(this.params.companyId));
    },
    handleClose2() {
      this.dialogVisible2 = false
    },
    async getShopOrder(id){ //店铺关联订单列表
      const { data } = await shopOrderList({shopId: id})
      this.shopOrder = data
    }
  },
};
</script>
<style lang="less" scoped>
.companyShopList-container {
  text-align: left;
  .common-screen-container {
    padding-left: 32px;
  }
  /deep/ .breadcrumb-container{
    padding: 0 32px;
  }
  /deep/ .el-tabs__content{
    padding: 0 32px;
  }
  /deep/ .el-button--text {
    white-space: normal;
    line-height: 20px;
    text-align: left;
  }
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
}
.dialog-input {
  width: 320px !important;
}
.el-cascader-menu__empty-text::after {
  content: "请先选择公司";
}
.gap{
  height: 16px;
  background-color: #ededed;
}
.title-container {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 20px 32px;
  align-items: center;
  position: relative;
  &::after {
    content: " ";
    background: #e6e6e6;
    width: 120%;
    height: 1px;
    position: absolute;
    left: -10%;
    bottom: -1px;
    z-index: 999999;
  }
  div {
    font-size: 24px;
    color: #101010;
  }
}
</style>
